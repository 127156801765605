import axios from 'axios';

const authentication = {
  async authenticateKey(key) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        key,
      },
    };

    const url = `${process.env.VUE_APP_API_GATEWAY}/auth`;

    const response = await axios.get(url, config);
    return response;
  },
};

export default authentication;
