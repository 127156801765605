exports.generateNextWorkout = (
  validation,
  equipmentSettings,
  muscleGroupSettings,
  futureExercises,
  finishedExercises,
  today = Date.now(),
) => {
  const nextWorkout = {
    validation,
    equipmentSettings,
    muscleGroupSettings,
    todaysExercises: [],
    futureExercises,
  };

  // process finished exercises
  finishedExercises.map((finishedExercise) => {
    // if sets less than goal sets
    if (finishedExercise.repsHistory.length < finishedExercise.goalReps.length) {
      nextWorkout.futureExercises.push(this.restTheExercise(finishedExercise, 1, today));
      finishedExercise.repsHistory = [];
      return null;
    }
    // if any reps less than the goal rep, rest this exercise
    for (let i = 0; i < finishedExercise.goalReps.length; i += 1) {
      if (finishedExercise.repsHistory[i] < finishedExercise.goalReps[i]) {
        nextWorkout.futureExercises.push(this.restTheExercise(finishedExercise, 1, today));
        finishedExercise.repsHistory = [];
        return null;
      }
    }

    finishedExercise.lastWorkedOut = today;

    // Determine if exercise has a restDayCount
    const successAndRest = finishedExercise.restDayCount > 0;

    // if set length less than the number of sets add one more set with minRepValue goal
    const maxSetValue = equipmentSettings[finishedExercise.equipment].sets.max;
    const minSetValue = equipmentSettings[finishedExercise.equipment].sets.min;
    const setIncrement = equipmentSettings[finishedExercise.equipment].sets.increment;

    const maxRepValue = equipmentSettings[finishedExercise.equipment].reps.max;
    const minRepValue = equipmentSettings[finishedExercise.equipment].reps.min;
    const repIncrement = equipmentSettings[finishedExercise.equipment].reps.increment;

    if (finishedExercise.goalReps.length < maxSetValue) {
      finishedExercise.repsHistory = [];
      const nextSetLength = Math.min(
        finishedExercise.goalReps.length + setIncrement,
        maxSetValue,
      );

      while (finishedExercise.goalReps.length < nextSetLength) {
        finishedExercise.goalReps.push(minRepValue);
      }

      if (successAndRest) {
        nextWorkout.futureExercises.push(this.restTheExercise(finishedExercise, 0, today));
      } else {
        nextWorkout.todaysExercises.push(finishedExercise);
      }
      return null;
    }

    // if any reps less than max, advance by rep increment
    let foundRepsLessThanMax = false;
    for (let i = 0; i < finishedExercise.repsHistory.length; i += 1) {
      if (finishedExercise.repsHistory[i] < maxRepValue) {
        foundRepsLessThanMax = true;
      }
    }

    if (foundRepsLessThanMax) {
      finishedExercise.goalReps = [];
      for (let i = 0; i < finishedExercise.repsHistory.length; i += 1) {
        finishedExercise.goalReps.push(
          Math.min(
            finishedExercise.repsHistory[i] + repIncrement,
            maxRepValue,
          ),
        );
      }
      finishedExercise.repsHistory = [];
      if (successAndRest) {
        nextWorkout.futureExercises.push(this.restTheExercise(finishedExercise, 0, today));
      } else {
        nextWorkout.todaysExercises.push(finishedExercise);
      }
      return null;
    }

    // advance to next workout (min reps/sets, higher weight) if nextWeight is higher
    finishedExercise.repsHistory = [];
    finishedExercise.goalReps = [];

    const nextWeights = equipmentSettings[finishedExercise.equipment].weight;
    const nextWeight = Math.min(
      ...nextWeights.filter((weight) => weight > finishedExercise.weight),
    );

    const weightIsDifferent = (nextWeight !== finishedExercise.weight) && nextWeight !== '' && nextWeight !== Infinity;

    if (weightIsDifferent) {
      finishedExercise.weight = nextWeight;
      for (let i = 0; i < minSetValue; i += 1) {
        finishedExercise.goalReps.push(minRepValue);
      }
    } else {
      for (let i = 0; i < maxSetValue; i += 1) {
        finishedExercise.goalReps.push(maxRepValue);
      }
    }

    if (successAndRest) {
      nextWorkout.futureExercises.push(this.restTheExercise(finishedExercise, 0, today));
    } else {
      nextWorkout.todaysExercises.push(finishedExercise);
    }
    return finishedExercise;
  });

  return nextWorkout;
};

exports.restTheExercise = (exercise, increment = 1, today) => {
  if (!('restDayCount' in exercise)) {
    exercise.restDayCount = 0;
  }

  const restStartsToday = exercise.repsHistory.length === 0;

  exercise.restDayCount += increment;

  if (restStartsToday) {
    exercise.restDayStart = today;
  } else {
    exercise.restDayStart = today + 60 * 60 * 24 * 1000;
  }

  exercise.repsHistory = [];
  return exercise;
};
