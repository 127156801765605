exports.prepareWorkout = (
  input,
  today = Date.now(),
) => {
  // add any ready future exercises
  const futureExercises = [];
  const readyExercises = [];
  let almostReadyExercises = input.todaysExercises;

  input.futureExercises.map((futureExercise) => {
    const todayNumber = Math.floor(today / 86400000);

    if (typeof futureExercise.restDayCount === 'undefined') {
      futureExercise.restDayCount = 0;
    }
    let isDoneResting = false;
    if (futureExercise.restDayCount <= 0) {
      isDoneResting = true;
    } else {
      const workedOutDayNumber = Math.floor(futureExercise.restDayStart / 86400000);
      const daysPassed = todayNumber - workedOutDayNumber;
      isDoneResting = (futureExercise.restDayCount - daysPassed) <= 0;
    }

    if (isDoneResting) {
      almostReadyExercises.push(futureExercise);
    } else {
      futureExercises.push(futureExercise);
    }
    return null;
  });

  almostReadyExercises = almostReadyExercises.map((exercise) => {
    if (typeof exercise.lastWorkedOut === 'undefined') {
      exercise.lastWorkedOut = 0;
    }
    return exercise;
  });

  almostReadyExercises.sort((a, b) => a.lastWorkedOut - b.lastWorkedOut);

  // filter out duplicate muscle groups
  const exerciseHistogram = {};

  almostReadyExercises.map((almostReadyExercise) => {
    const muscleGroupKeys = Object.keys(input.muscleGroupSettings);

    if (muscleGroupKeys.includes(almostReadyExercise.muscleGroup)) {
      const name = almostReadyExercise.muscleGroup;
      const muscleGroup = input.muscleGroupSettings[name];
      if (typeof exerciseHistogram[name] === 'undefined') {
        exerciseHistogram[name] = 0;
      }
      if (exerciseHistogram[name] >= muscleGroup.exercisesOfThisTypeInOneWorkout) {
        futureExercises.push(almostReadyExercise);
        return null;
      }
      readyExercises.push(almostReadyExercise);
      exerciseHistogram[name] += 1;
    } else {
      readyExercises.push(almostReadyExercise);
    }
    return null;
  });

  return {
    readyExercises,
    futureExercises,
  };
};
