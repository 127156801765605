<template>
  <div class="workout">
    <!-- Overlay -->
    <v-ace-editor
      v-model:value="content"
      v-show="!readied"
      class="overlay-editor"
      @init="editorInit"
      lang="json"
      theme="one_dark"
      id="ready-text" />
    <v-ace-editor
      v-model:value="errors"
      v-show="!readied"
      class="overlay-editor"
      @init="editorInit"
      lang="json"
      theme="one_dark"
      id="errors-text" />
    <button @click="userStartsWorkout()"
      id="ready-up"
      class="overlay"
      v-show="!readied">Ready up</button>
    <div class="workout-zone" v-show="zoneReadied">
    <!-- Workout App -->
    <h1 v-if="this.currentExercise.name === 'Rest'">Resting</h1>
    <h1 v-else>{{ this.currentExercise.name }} @ {{ this.currentExercise.weight }} lbs</h1>
    <div id="run-workout">
      <button v-if="!isPlaying" @click="startWorkout()">Start</button>
      <button v-else @click="userPausesWorkout()">Pause</button>
      <button @click="userEndsWorkout()">End</button>
    </div>
    <div id="current-workout-stats">
      <h2 class="card-header">Reps:
        <span class="h2-1" v-if="(typeof this.currentExercise.repsHistory) !== 'undefined'
                                  && (typeof this.currentExercise.goalReps) !== 'undefined'" >
          <span
            v-bind:key="index"
            v-for="(repHistory, index) in this.currentExercise.repsHistory">
            {{repHistory}}
            <span v-if="index < this.currentExercise.repsHistory.length - 1"> - </span>
          </span>
          <span
            v-bind:key="index"
            v-for="index in
              (this.currentExercise.goalReps.length - this.currentExercise.repsHistory.length + 1)">
            <span v-if="this.currentExercise.repsHistory.length === 0 && index === 1
                        && this.currentExercise.name !== 'Rest'
                        && (index < this.currentExercise.goalReps.length
                                - this.currentExercise.repsHistory.length + 1)"> _</span>
            <span v-if="this.currentExercise.repsHistory.length === 0 && index !== 1
                        && this.currentExercise.name !== 'Rest'
                        && (index < this.currentExercise.goalReps.length
                                - this.currentExercise.repsHistory.length + 1)"> - _</span>
            <span v-if="this.currentExercise.repsHistory.length !== 0 && index === 1
                        && this.currentExercise.name !== 'Rest'
                        && (index < this.currentExercise.goalReps.length
                                - this.currentExercise.repsHistory.length + 1)"> - _</span>
            <span v-if="this.currentExercise.repsHistory.length !== 0 && index !== 1
                        && this.currentExercise.name !== 'Rest'
                        && (index < this.currentExercise.goalReps.length
                                - this.currentExercise.repsHistory.length + 1)"> - _</span>
          </span>
        </span>
        <input pattern="\d*" type="number" class="h2-input" v-model="actualReps" /> /
        <span class="h2-1">{{
            this.currentExercise.goalReps[this.currentExercise.repsHistory.length]
          }}
        </span>
      </h2>
    </div>
    <div id="video" ref="doutube">
      <YouTube
        v-if="this.currentExercise.videoUrl !== '' && this.currentExercise.name !== 'Rest'"
        :width="'100%'"
        :height="ytHeight"
        :src="this.currentExercise.videoUrl"
        :vars="ytVars"
        @ready="onReady"
        @state-change="onStateChange"
        ref="youtube" />
    </div>
    <div id="current-workout-options">
      <button class="rest"
        :disabled="!workoutActive || this.actualReps === ''"
        @click="userCompletesSet()">Set Done</button>
    </div>
    <div id="exercises">
      <div id="ready-exercises">
        <h3>Ready</h3>
        <div class="card ready-exercise"
            v-for="(readyExercise, index) in readyExercises" v-bind:key="index">
          <div class="left header">{{readyExercise.name}}</div>
          <div class="right header">@{{readyExercise.weight}} lbs</div>
          <div class="left sub-header">{{readyExercise.muscleGroup}}</div>
          <div class="right sub-header">{{readyExercise.equipment}}</div>
          <div class="exit">
            <button class="stop" @click="userRemovesExerciseFromReadyList(readyExercise)">X</button>
          </div>
          <div class="side-bar">Ready</div>
          <div class="line"></div>
          <div class="button-zone">
            <button @click="userStartsExercise(readyExercise)">Start</button>
          </div>
        </div>
      </div>
      <div id="resting-exercises">
        <h3>Resting</h3>
        <div class="card ready-exercise"
          v-for="(restingExercise, index) in restingExercises" v-bind:key="index">
          <div class="left header">{{restingExercise.name}}</div>
          <div class="right header">@{{restingExercise.weight}} lbs</div>
          <div class="left sub-header">{{restingExercise.muscleGroup}}</div>
          <div class="right sub-header">{{restingExercise.equipment}}</div>
          <div class="exit">
            <button class="stop" @click="userRemovesExerciseFromRestingList(restingExercise)">
              X
            </button>
          </div>
          <div class="side-bar">{{Math.floor(restingExercise.restTimeLeft/60)}} min<br />
          {{restingExercise.restTimeLeft % 60}} secs</div>
          <div class="line"></div>
          <div class="button-zone">
            <button @click="addRestTimeExercise(restingExercise, -5)">-5 secs</button>
            <button @click="addRestTimeExercise(restingExercise, 5)">+5 secs</button>
          </div>
        </div>
      </div>
    </div>
    <span>Elapsed Time: {{ Math.floor(this.counter/60) }} min
      {{ this.counter % 60}} secs
    </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { VAceEditor } from 'vue3-ace-editor';
import YouTube from 'vue3-youtube';
import authenticationApi from '../api/authentication';
import { generateNextWorkout } from '../cloud-functions/generateNextWorkout';
import { prepareWorkout } from '../cloud-functions/prepareWorkout';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-one_dark';

export default {
  name: 'Home',
  data() {
    return {
      actualReps: '',
      autoPlay: false,
      counter: 0,
      currentExercise: {
        name: 'Rest',
        type: '',
        muscleGroup: '',
        goalReps: [0],
        repsHistory: [],
        restTime: 0,
        restTimeLeft: 0,
        videoUrl: '',
      },
      editorInit: '',
      errors: '',
      content: '',
      isPlaying: false,
      readied: false,
      zoneReadied: true,
      ytIsReady: false,
      readyExercises: [],
      restingExercises: [],
      interval: null,
      workoutActive: false,
      ytVars: {
        controls: 0,
        // modestbranding: 0,
        playsinline: 1,
        disablekb: 1,
        fs: 0,
      },
      workoutReport: {
        // finished exercises (completed or stopped early)
        finishedExercises: [],

        // exercises that were from the future
        futureExercises: [],
      },
      ytHeight: 300,
    };
  },
  components: {
    VAceEditor,
    YouTube,
  },
  methods: {
    /* User Actions */
    async userStartsWorkout() {
      // Validations
      const { input, outErrors } = this.performValidations(this.content);

      if (outErrors.errors.length > 0) {
        this.errors = JSON.stringify(outErrors, null, 4);
        this.refreshWorkoutView();
        return;
      }

      // Authentication
      try {
        await authenticationApi.authenticateKey(input.validation.key);
      } catch (error) {
        // todo: brainched style user key validation
        outErrors.errors.push({
          name: 'Invalid Key',
          description: 'The supplied key is invalid',
        });

        this.errors = JSON.stringify(input, null, 4);
        this.refreshWorkoutView();
        return;
      }
      this.readied = true;
      this.zoneReadied = true;

      // Preparation
      const { readyExercises, futureExercises } = prepareWorkout(input);
      this.readyExercises = readyExercises;
      this.workoutReport.futureExercises = futureExercises;
      this.startWorkout();
    },
    userStartsExercise(newCurrentExercise) {
      if (!this.workoutActive || this.currentExercise.name !== 'Rest') {
        return;
      }
      this.currentExercise = newCurrentExercise;

      const newReadyExercises = [];

      for (let i = 0; i < this.readyExercises.length; i += 1) {
        const readyExercise = this.readyExercises[i];

        if (readyExercise.name !== newCurrentExercise.name) {
          newReadyExercises.push(readyExercise);
        }
      }

      this.readyExercises = newReadyExercises;
    },
    userCompletesSet() {
      this.ytIsReady = false;
      const queuedUpExercise = this.advanceExercise(this.currentExercise);

      if (queuedUpExercise) {
        this.restingExercises.push(queuedUpExercise);
        // sort resting Exercises by time
      }

      this.currentExercise = this.defaultExercise();
    },
    userRemovesExerciseFromReadyList(removingReadyExercise) {
      this.workoutReport.finishedExercises.push(removingReadyExercise);
      const newReadyExercises = [];

      for (let i = 0; i < this.readyExercises.length; i += 1) {
        const readyExercise = this.readyExercises[i];

        if (readyExercise.name !== removingReadyExercise.name) {
          newReadyExercises.push(readyExercise);
        }
      }

      this.readyExercises = newReadyExercises;
    },
    userRemovesExerciseFromRestingList(removingRestingExercise) {
      this.workoutReport.finishedExercises.push(removingRestingExercise);
      const newRestingExercises = [];

      for (let i = 0; i < this.restingExercises.length; i += 1) {
        const restingExercise = this.restingExercises[i];

        if (restingExercise.name !== removingRestingExercise.name) {
          newRestingExercises.push(restingExercise);
        }
      }

      this.restingExercises = newRestingExercises;
    },
    userPausesWorkout() {
      this.isPlaying = false;
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.workoutActive = false;
      if (this.currentExercise.name !== 'Rest') {
        this.$refs.youtube.pauseVideo();
      }
    },
    userEndsWorkout() {
      this.userPausesWorkout();
      if (this.currentExercise.name !== 'Rest') {
        this.workoutReport.finishedExercises.push(this.currentExercise);
        this.currentExercise = this.defaultExercise();
      }
      for (let i = 0; i < this.restingExercises.length; i += 1) {
        const restingExcercise = this.restingExercises[i];

        if (restingExcercise.name !== 'Rest') {
          this.workoutReport.finishedExercises.push(restingExcercise);
        }
      }
      for (let i = 0; i < this.readyExercises.length; i += 1) {
        const finishedExercise = this.readyExercises[i];

        if (finishedExercise.name !== 'Rest') {
          this.workoutReport.finishedExercises.push(finishedExercise);
        }
      }
      this.readied = false;
      this.zoneReadied = false;
      const input = JSON.parse(this.content);

      const nextWorkout = generateNextWorkout(
        input.validation,
        input.equipmentSettings,
        input.muscleGroupSettings,
        this.workoutReport.futureExercises,
        this.workoutReport.finishedExercises,
      );

      this.content = JSON.stringify(nextWorkout, null, 4);

      // reset the workout
      this.resetWorkout();
    },

    /* Helper Functions */
    addRestTimeExercise(restingExercise, time) {
      restingExercise.restTime += time;
      restingExercise.restTimeLeft += time;
    },
    advanceExercise(finishedExercise) {
      finishedExercise.repsHistory.push(parseInt(this.actualReps, 10));

      if (finishedExercise.repsHistory.length >= finishedExercise.goalReps.length) {
        this.workoutReport.finishedExercises.push(finishedExercise);
        return false;
      }

      finishedExercise.restTimeLeft = finishedExercise.restTime;
      return finishedExercise;
    },
    defaultExercise() {
      return {
        name: 'Rest',
        type: '',
        muscleGroup: '',
        goalReps: [0],
        repsHistory: [],
        restTime: 0,
        restTimeLeft: 0,
      };
    },
    performValidations(content) {
      const outErrors = {
        errors: [],
      };

      const input = this.tryToParseJson(content);

      if (!input) {
        outErrors.errors.push({
          name: 'Invalid JSON',
          description: 'The supplied json will not parse. Please visit jsonlint.com to confirm validity.',
        });
        return {
          input,
          outErrors,
        };
      }

      // validate validation
      if (typeof input.validation === 'undefined'
          || typeof input.validation.key === 'undefined'
          || typeof input.validation.user === 'undefined'
          || input.validation.user !== 'user') {
        outErrors.errors.push({
          name: 'Invalid Key',
          description: 'The supplied key is invalid.',
        });
      }

      let allExercises = [];
      let hasTodaysExercises = false;
      let hasFutureExercises = false;
      if (typeof input.todaysExercises === 'object') {
        hasTodaysExercises = true;
        allExercises = [...allExercises, ...input.todaysExercises];
      }

      if (typeof input.futureExercises === 'object') {
        hasFutureExercises = true;
        allExercises = [...allExercises, ...input.futureExercises];
      }

      if (!hasTodaysExercises) {
        outErrors.errors.push({
          name: 'Missing Todays Exercises Section',
          description: 'The \'todaysExercises\' section is missing.',
        });
      }

      if (!hasFutureExercises) {
        outErrors.errors.push({
          name: 'Missing Future Exercises Section',
          description: 'The \'futureExercises\' section is missing.',
        });
      }

      const equipment = allExercises.map((exercise) => exercise.equipment);
      let equipmentIsMissing = false;
      for (let i = 0; i < equipment.length; i += 1) {
        // validate equipment is present
        if (!(equipment[i] in input.equipmentSettings)) {
          outErrors.errors.push({
            name: 'Missing Equipment',
            description: `The equipment '${equipment[i]}' is not found in your equipmentSettings.`,
          });
          equipmentIsMissing = true;
        }
      }
      // validate that goalReps will allow for a successful workout
      if (!equipmentIsMissing) {
        for (let i = 0; i < allExercises.length; i += 1) {
          const exercise = allExercises[i];
          const equipmentForExercise = input.equipmentSettings[exercise.equipment];
          if (exercise.goalReps.length < equipmentForExercise.sets.min) {
            outErrors.errors.push({
              name: 'Goal Mismatch',
              description: `The amount of sets for '${exercise.name} will not allow you to succeed with the equipment goal.`,
            });
          }
          if (exercise.goalReps.length > equipmentForExercise.sets.max) {
            outErrors.errors.push({
              name: 'Goal Mismatch',
              description: `The amount of sets for '${exercise.name} is more than expected for the equipment goal.`,
            });
          }
          for (let j = 0; j < exercise.goalReps.length; j += 1) {
            const repAmount = exercise.goalReps[j];
            if (repAmount < equipmentForExercise.reps.min) {
              outErrors.errors.push({
                name: 'Goal Mismatch',
                description: `The amount of reps for '${exercise.name} will not allow you to succeed with the equipment goal.`,
              });
            }
            if (repAmount > equipmentForExercise.reps.max) {
              outErrors.errors.push({
                name: 'Goal Mismatch',
                description: `The amount of reps for '${exercise.name} is more than expected with the equipment goal.`,
              });
            }
          }
        }
      }

      return {
        input,
        outErrors,
      };
    },
    restNormal() {
      const queuedUpExercise = this.advanceExercise(this.currentExercise);

      if (queuedUpExercise) {
        this.restingExercises.push(queuedUpExercise);
        // sort resting Exercises by time
      }

      if (this.readyExercises.length > 0) {
        this.currentExercise = this.readyExercises.shift();
      } else {
        this.currentExercise = this.defaultExercise();
      }
    },
    tryToParseJson(json) {
      try {
        const result = JSON.parse(json);
        return result;
      } catch (e) {
        return false;
      }
    },

    /* Workout Loop */
    workoutLoop() {
      if (this.currentExercise.name === 'Rest' && this.readyExercises.length > 0 && this.autoPlay) {
        this.setCurrentExercise(this.readyExercises.shift());
      }

      const newRestingExercises = [];

      for (let i = 0; i < this.restingExercises.length; i += 1) {
        const restingExercise = this.restingExercises[i];
        restingExercise.restTimeLeft -= 1;

        if (restingExercise.restTimeLeft <= 0) {
          this.readyExercises.push(restingExercise);
        } else {
          newRestingExercises.push(restingExercise);
        }
      }

      this.restingExercises = newRestingExercises;
      this.counter += 1;
      if (this.currentExercise.name !== 'Rest' && this.ytIsReady) {
        if (this.$refs.youtube.getCurrentTime() > this.currentExercise.videoEnd) {
          this.$refs.youtube.seekTo(this.currentExercise.videoStart);
        }
      }
    },
    startWorkout() {
      this.isPlaying = true;
      if (!this.interval) {
        this.interval = setInterval(this.workoutLoop, 1000);
      }
      this.workoutActive = true;

      if (this.currentExercise.name !== 'Rest') {
        this.$refs.youtube.playVideo();
      }
    },
    resetWorkout() {
      this.counter = 0;
      this.restingExercises = [];
      this.readyExercises = [];
      this.workoutReport = {
        // finished exercises (completed or stopped early)
        finishedExercises: [],

        // exercises that were from the future
        futureExercises: [],
      };
    },
    /* Getters and Setters */
    refreshWorkoutView() {
      this.readied = true;
      this.zoneReadied = true;
      this.readied = false;
      this.zoneReadied = false;
    },
    setCurrentExercise(newExercise) {
      this.currentExercise = newExercise;
      this.onReady();
    },
    /* Events */
    onReady() {
      this.ytIsReady = true;
      this.$refs.youtube.mute();
      this.$refs.youtube.seekTo(this.currentExercise.videoStart);
      this.$refs.youtube.playVideo();
    },
    onStateChange(event) {
      const state = event.data;

      switch (state) {
        case -1: // unstarted
          this.onReady();
          break;
        case 0: // ended
          this.$refs.youtube.seekTo(62);
          this.$refs.youtube.playVideo();
          break;
        case 1: // playing
          break;
        case 2:
          break;
        case 3: // buffering
          this.onReady();
          break;
        default:
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', (event) => {
      event.returnValue = 'Are you sure you\'d like to reload?';
    });
  },
  mounted() {
    // this.readyExercises = this.getExercises();
    // this.content = JSON.stringify(this.getExercises());
    const errorMessages = {
      notes: [
        'To begin, put your json formatted workout in the textbox above.',
        'If you have any errors, they will show up here.',
      ],
    };
    this.errors = JSON.stringify(errorMessages, null, 4);
    this.ytHeight = (this.$refs.doutube.clientWidth / 16) * 9;
    this.zoneReadied = false;
  },
};
</script>
